import create from 'zustand'

import { SkillSelectType } from 'components/selects/LargeSkillSelect/types'

export interface ISkillModal {
  name: SkillSelectType
  showMobileSkillSelectModal: boolean
}

export interface IModalStore {
  showAfterPayingProFormaModal: boolean
  showProhibitNavigationModal: boolean
  showConfirmEmailModal: boolean
  showMobileSkillSelectModal: ISkillModal[]
  showMainEmployerOnlyModal: boolean
  emailResend: string | null
  setShowAfterPayingProFormaModal: (
    showAfterPayingProFormaModal?: boolean
  ) => void
  setShowProhibitNavigationModal: (
    showProhibitNavigationModal?: boolean
  ) => void
  setShowConfirmEmailModal: (showConfirmEmailModal?: boolean) => void
  setShowMainEmployerOnlyModal: (showMainEmployerOnlyModal?: boolean) => void
  setShowMobileSkillSelectModal: (
    name: SkillSelectType,
    showMobileSkillSelectModal: boolean
  ) => void
  getShowMobileSkillSelectModal: (name: string) => boolean
  setEmailResend: (email: string | null) => void
  clearSkillSelectModal: () => void
  clearStore: () => void
}

const useModalStore = create<IModalStore>()((set, get) => ({
  showAfterPayingProFormaModal: false,
  showProhibitNavigationModal: false,
  showMobileSkillSelectModal: [
    { name: SkillSelectType.Obligatory, showMobileSkillSelectModal: false },
    { name: SkillSelectType.Preferable, showMobileSkillSelectModal: false }
  ],
  showConfirmEmailModal: false,
  emailResend: null,
  showMainEmployerOnlyModal: false,

  setShowMobileSkillSelectModal: (name: SkillSelectType, show) =>
    set((state) => ({
      showMobileSkillSelectModal: state.showMobileSkillSelectModal.map(
        (modal) =>
          modal.name === name
            ? { ...modal, showMobileSkillSelectModal: show }
            : modal
      )
    })),

  getShowMobileSkillSelectModal: (name) =>
    get().showMobileSkillSelectModal.find((modal) => modal.name === name)
      ?.showMobileSkillSelectModal ?? false,
  setShowAfterPayingProFormaModal: (showAfterPayingProFormaModal?: boolean) =>
    set(() => ({ showAfterPayingProFormaModal })),

  setShowProhibitNavigationModal: (showProhibitNavigationModal?: boolean) =>
    set(() => ({ showProhibitNavigationModal })),

  setShowConfirmEmailModal: (showConfirmEmailModal?: boolean) =>
    set(() => ({ showConfirmEmailModal })),

  setShowMainEmployerOnlyModal: (showMainEmployerOnlyModal?: boolean) =>
    set(() => ({ showMainEmployerOnlyModal })),

  setEmailResend: (emailResend: string | null) => set(() => ({ emailResend })),

  clearSkillSelectModal: () =>
    set(() => ({
      showMobileSkillSelectModal: [
        { name: SkillSelectType.Obligatory, showMobileSkillSelectModal: false },
        { name: SkillSelectType.Preferable, showMobileSkillSelectModal: false }
      ]
    })),

  clearStore: () =>
    set(() => ({
      showMobileSkillSelectModal: [],
      showAfterPayingProFormaModal: false,
      showProhibitNavigationModal: false,
      showConfirmEmailModal: false,
      showMainEmployerOnlyModal: false,
      emailResend: null
    }))
}))

export default useModalStore
