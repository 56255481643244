import { ReactNode } from 'react'
import { IBusinessFunction, ISkill } from 'data-transfers/dto'

export type AdvertSkillSections = 'obligatorySkills' | 'preferableSkills'

export const priorityMap: Record<AdvertSkillSections, string> = {
  obligatorySkills: 'Mandatory',
  preferableSkills: 'Optional'
}

export enum SkillSelectType {
  Obligatory = 'obligatorySkills',
  Preferable = 'preferableSkills'
}

export interface AdvertSkillSelectProps {
  data: IBusinessFunction[]
}

export type ISkillComponentsProps = {
  name: SkillSelectType
  skillsOperations: ISkillsOperations
}

export interface ISkillAccordionItemProps extends ISkillComponentsProps {
  title: string
  subtitle: string
  children: ReactNode
}

export interface ISkillsOperations {
  removeSelectedOne: (name: string, skillId: string) => void
  removeOne: (name: string, skillId: string) => void
  removeAll: (name: string) => void
  onCheckbox: (name: string, skillId: string, checked: boolean) => void
  onApply: (name: string) => void
  onCancel: (name: string) => void
  updateSkill?: (name: string, skillId: string, experience: number) => void
}

export type ILargeSkillSelectProps = ISkillComponentsProps &
  AdvertSkillSelectProps

export interface ISkillExperienceProps extends ISkillComponentsProps {
  skill: ISkill & { years: number | null }
  businessFunction?: string
  yearPicker?: boolean
}

export interface ISkillSelectProps extends ILargeSkillSelectProps {
  className?: string
  scrollClassName?: string
  searchClassName?: string
}

export type ISkillExperienceSelectProps = ISkillComponentsProps &
  AdvertSkillSelectProps & {
    onStepBack?: () => void
    className?: string
  }
